import React from "react";

// Customizable Area Start
import { AppBar, Toolbar, Typography, TextField, Box, IconButton, Drawer, List, ListItem, Grid, Divider } from '@mui/material';
import { Search as SearchIcon, Menu as MenuIcon } from '@mui/icons-material';
import { imgLogo } from "./assets";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  currentPath = this.props.navigation?.history?.location?.pathname;

  navItems = [
    { label: 'Home', path: '/LandingPage', block: "LandingPage" },
    { label: 'Search History', path: '/SearchHistory', block: "SearchHistory" },
    { label: 'Profile', path: '/Profile', block: "Profile" },
    { label: 'Contact Us', path: '/Contact-Us', block: "ContactUs" },
  ];
  // Customizable Area End

  render() {
    // Customizable Area Start
    const styles = getStyles(this.props.isLandingPage);
    return (
      <AppBar position="static" style={styles.appBar}>
        <Box sx={styles.toolbar}>
          <Toolbar sx={styles.orgToolbar}>
            <Grid container spacing={8}>
              <Grid item lg={4} md={4} sm={4} xs={10}>
                <Box sx={styles.logo} 
                      data-test-id="nav-item-redirect"
                      onClick={() => this.handleNavigation("LandingPage")}>
                  <img src={imgLogo} />
                  <Typography variant="h6" sx={styles.logoText}>
                    {configJSON.logoText}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={2} sx={styles.secondGrid}>
                <Box sx={styles.navLinks}>
                  {this.navItems.map((item) => (
                    <Typography
                      key={item.label}
                      variant="body1"
                      data-test-id="nav-item"
                      sx={styles.navLink(item.path, this.currentPath)}
                      onClick={() => this.handleNavigation(item.block)}
                    >
                      {item.label}
                    </Typography>
                  ))}
                  <div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={styles.search}
                      InputProps={{
                        startAdornment: <SearchIcon sx={styles.searchIcon} />,
                      }}
                      placeholder="Search"
                    />
                  </div>
                </Box>
                <Box sx={styles.menuIconContainer}>
                  <IconButton
                    data-test-id="menu-icon-open"
                    edge="start"
                    sx={styles.menuIcon}
                    aria-label="menu"
                    onClick={this.handleDrawerOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    data-test-id="menu-drawer"
                    anchor="top"
                    open={this.state.isDrawerOpen}
                    onClose={this.handleDrawerClose}
                    sx={styles.drawer}
                    BackdropProps={{ invisible: true }}
                  >
                    <List sx={styles.drawerList}>
                      {this.navItems.map((item) => (
                        <ListItem sx={styles.drawerListItem} key={item.label}>
                          <Typography
                            variant="body1"
                            data-test-id="nav-item"
                            sx={styles.drawerListItemLink(
                              item.path,
                              this.currentPath
                            )}
                            onClick={() => this.handleNavigation(item.block)}
                          >
                            {item.label}
                          </Typography>
                        </ListItem>
                      ))}
                      <ListItem sx={styles.drawerListItem}>
                        <div>
                          <TextField
                            fullWidth
                            variant="outlined"
                            sx={styles.search}
                            InputProps={{
                              startAdornment: (
                                <SearchIcon sx={styles.searchIcon} />
                              ),
                            }}
                            placeholder="Search"
                          />
                        </div>
                      </ListItem>
                    </List>
                  </Drawer>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
          {!this.props.isLandingPage && <Divider />}
        </Box>
      </AppBar>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const getStyles = (isLandingPage : boolean) => ({
  appBar: {
    backgroundColor: isLandingPage ? '#11294B' : '#fff',
    zIndex: 1100,
    boxShadow: "none",
  },
  toolbar: {
    padding:"20px 80px 0px",
    "@media (max-width: 1149px) and (min-width: 802px)": {
      padding: "15px 40px 0px",
    },
    "@media (max-width:801px)": {
      padding: "10px 10px 0px",
    },
  },
  orgToolbar:{
    paddingBottom:"20px",
    "@media (max-width: 1149px) and (min-width: 802px)": {
      paddingBottom: "15px",
    },
    "@media (max-width:801px)": {
      paddingBottom: "10px",
    },
  },
  drawer: {
    top: '90px',
    '& .MuiDrawer-paper': {
      top: '90px',
      zIndex: 1000,
      boxShadow: "none",
    },
  },
  drawerList:{
    backgroundColor: isLandingPage ? '#11294B' : '#fff',
  },
  drawerListItem:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  drawerListItemLink: (path: string, currentPath: string) => ({
    fontFamily: "Helvetica Neue",
    textAlign:'center',
    color: isLandingPage
      ? path === currentPath
        ? "#fff"
        : "#475569"
      : path === currentPath
      ? "#334155"
      : "#64748B",
    textDecoration: "none",
    cursor: "pointer",
  }),
  logo:{
    display: "flex",
    alignItems: "center",
  },
  logoText: {
    color: isLandingPage ? "#ffffff" : "#252B42",
    fontFamily: "Tiempos Headline",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    marginLeft: "10px",
  },
  secondGrid:{
    display:"flex",
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  navLinks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    "@media (max-width:801px)": {
      display: 'none',
    },
  },
  menuIconContainer:{
    display: 'none',
    "@media (max-width:801px)": {
      display:"flex",
    },
  },
  menuIcon:{
    color: isLandingPage ? "#ffffff" : "#252B42",
    cursor: 'pointer',
  },
  navLink: (path: string, currentPath: string) => ({
    fontFamily: "Helvetica Neue",
    marginLeft: "20px",
    marginRight: "20px",
    color: isLandingPage
      ? path === currentPath
        ? "#fff"
        : "#475569"
      : path === currentPath
      ? "#334155"
      : "#64748B",
    textDecoration: "none",
    cursor: "pointer",
  }),
  search:{
    backgroundColor: isLandingPage ? '#11294B' : "#ffffff",
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: isLandingPage ? '#F1F5F9' : "#CBD5E1",
        borderRadius: '98px',
      },
      '&:hover fieldset': {
        borderColor: isLandingPage ? '#F1F5F9' : "#CBD5E1",
      },
      '&.Mui-focused fieldset': {
        borderColor: isLandingPage ? '#F1F5F9' : "#CBD5E1",
      },
    },
    '& .MuiInputBase-input': {
      color: isLandingPage ? '#F1F5F9' : "#94A3B8",
    },
  },
  searchIcon:{
    color: isLandingPage ? '#F1F5F9' : "#94A3B8",
    paddingRight: '10px',
  },
});
// Customizable Area End
